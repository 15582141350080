<template>
  <div>
    <b-tabs title-link-class="schedule-list__tab" v-model="selectedTab">
      <b-tab
        :title="tab.title"
        :active="tab.isActive"
        v-for="(tab, i) in tabs"
        :key="i"
      >
        <div class="management-list">
          <div v-if="tab.value == tabs[selectedTab].value && !isLoadingClasses">
            <div v-if="(getFilteredClasses || []).length > 0">
              <b-table
                small
                borderless
                responsive
                :items="items"
                :fields="fields"
                :per-page="perPage"
                :current-page="currentPage"
                v-if="!isLoadingClasses"
                class="p-3"
                style="min-height: 225px"
              >
                <template #cell(show_details)="data">
                  <b-form-checkbox
                    :id="data.item.id"
                    v-model="user_ids"
                    :value="data.item.id"
                  ></b-form-checkbox>
                </template>
                <template #cell(title)="data">
                  <p class="learner-text-bold">{{ data.item.title }}</p>
                </template>
                <template #cell(sessionname)="data">
                  <p class="learner-text">{{ data.item.sessionname }}</p>
                </template>
                <template #cell(sessions_count)="data">
                  <p class="learner-text">{{ data.item.sessions_count }}</p>
                </template>
                <template #cell(presence_count)="data">
                  <p class="learner-text">{{ data.item.presence_count }}</p>
                </template>
                <template #cell(absence_count)="data">
                  <p class="learner-text">{{ data.item.absence_count }}</p>
                </template>
                <template #cell(date_at)="data">
                  <p class="learner-text">{{ data.item.date_at }}</p>
                </template>
                <template #cell(start_time_at)="data">
                  <p class="learner-text">
                    {{ data.item.start_time_at }} - {{ data.item.end_time_at }}
                  </p>
                </template>
                <template #cell(options)="data">
                  <b-button
                    :id="'popover' + data.item.sid"
                    class="b-transparent float-right"
                  >
                    <img src="@/assets/three-dots.svg" alt />
                  </b-button>
                  <b-popover
                    :target="'popover' + data.item.sid"
                    triggers="focus"
                    placement="top"
                  >
                    <div class="edit-option">
                      <div @click="markAttendance(data.item)">
                        Mark attendance
                      </div>
                      <div class="separator"></div>
                      <div @click="openEnrolledUser(data.item)">
                        View Enrolled users
                      </div>
                    </div>
                  </b-popover>
                </template>
              </b-table>

              <f2f-pagination
                class="mr-1 mb-2"
                :total-rows="rows"
                v-mode="currentPage"
                @page="onPage"
              />
            </div>
          </div>
          <div class="loader" v-if="isLoadingClasses">
            <b-spinner label="Loading..."></b-spinner>
          </div>
          <div
            v-if="!isLoadingClasses && (getFilteredClasses || []).length == 0"
            class="no-record-holder"
          >
            <div>
              <img src="@/assets/wrappers/norecord.svg" />
              <div>{{ tabs[selectedTab].noRecordMessage }}</div>
            </div>
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    classId: {
      type: Number,
      default: -1
    }
  },
  data() {
    return {
      selectedTab: 0,
      showmodal: false,
      perPage: 10,
      currentPage: 1,
      detailsShowing: "",
      currentSessionId: -1,
      confirmCancel: false,
      unableCancel: false,
      cancelError: false,
      user_ids: [],
      actionType: "single",
      searchTerm: "",
      disableButton: false,
      tabs: [
        {
          title: "Future",
          isActive: true,
          value: "future",
          noRecordMessage: "No Attendance found!"
        },
        {
          title: "Ongoing",
          isActive: false,
          value: "ongoing",
          noRecordMessage: "No Attendance found!"
        },
        {
          title: "Completed",
          isActive: false,
          value: "completed",
          noRecordMessage: "No Attendance found!"
        },
        {
          title: "Cancelled",
          isActive: false,
          value: "canceled",
          noRecordMessage: "No Attendance found!"
        }
      ],
      fields: [
        {
          key: "title",
          label: "Class Name"
        },
        {
          key: "sessionname",
          label: "Session name"
        },
        {
          key: "sessions_count",
          label: "Enrolled users",
          thStyle: { width: "20%" }
        },
        {
          key: "presence_count",
          label: "Presences"
        },
        {
          key: "absence_count",
          label: "Absents"
        },
        {
          key: "date_at",
          label: "Session date"
        },
        {
          key: "start_time_at",
          label: "Session time"
        },
        {
          key: "options",
          label: ""
        }
      ]
    };
  },
  components: {},
  computed: {
    ...mapGetters(["getFilteredClasses", "isLoadingClasses"]),

    items() {
      return (this.getFilteredClasses || []).map((cls) => {
        return {
          title: cls.lxpclass.title,
          sessionname: cls.title,
          sessions_count: cls.lxpclass.sessions_count,
          presence_count: cls.presence_count,
          absence_count: cls.absence_count,
          date_at: this.formatDateByPattern(cls.date_at, "DD/MM/YYYY"),
          start_time_at: this.parseAndFormatDate(
            cls.start_time_at,
            "HH:mm",
            "hh:mm"
          ),
          end_time_at: this.parseAndFormatDate(
            cls.end_time_at,
            "HH:mm",
            "hh:mm"
          ),
          sid: cls.id,
          cid: cls.lxpclass.id
        };
      });
    },
    rows() {
      return this.items.length;
    },
    isDisabled() {
      return this.user_ids[0] > 0;
    }
  },
  watch: {
    selectedTab(selected) {
      this.$store.dispatch("listFilteredClassesForInstuctor", {
        course_id: this.$route.params.id,
        status: this.tabs[selected].value
      });
    }
  },
  async mounted() {
    this.$store.dispatch("listFilteredClassesForInstuctor", {
      course_id: this.$route.params.id
    });
  },
  methods: {
    async markPresent() {
      const toPost = {
        course_id: this.$route.params.id,
        class_id: this.$route.params.classId,
        session_id: 2,
        payload: {
          user_ids: this.user_ids,
          has_attended: true
        }
      };
      try {
        await this.$store.dispatch("updateMarkAttendance", toPost);
      } catch (err) {
        console.log(err);
      }
    },
    markAttendance(item) {
      this.$router.push({
        name: "attendance",
        params: {
          classId: item.cid,
          session_id: item.sid
        }
      });
    },
    openEnrolledUser(item) {
      this.$router.push({
        name: "enrollement",
        params: {
          classId: item.cid
        }
      });
    },
    onPage(page) {
      this.perPage = page.perPage;
      this.currentPage = page.currentPage;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .modal-body {
  padding: 0px;

  .form-space {
    min-width: auto;
  }
}

.modal-content {
  padding: 1.5rem;
  border: none;
  border-left: 5px solid #ffaa15;
}

.no-record-holder {
  height: 60vh;
  min-height: 300px;
  border: 1px solid #b2b2b2;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;

  img {
    width: 80px;
    margin-bottom: 1rem;
  }
}

.loader {
  margin-top: 20vh;
  text-align: center;
}

::v-deep {
  thead {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.1px;
    color: #a0a4a8;
  }
}

::v-deep tbody {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
}

::v-deep .nav-tabs {
  font-size: 14px;
}

.management-list {
  min-height: 300px;
  border: 1px solid #b2b2b2;
  background: white;
}

input.b-form-input {
  border-radius: 10px;
}

.learner-text-bold {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  // display: flex;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 205px;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow-wrap: break-word;
  word-break: break-all;
}

.learner-text {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #52575c;
  white-space: nowrap;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdown {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #0043ac;
}

::v-deep .btn-outline-primary {
  color: #0043ac;
  border-color: #0043ac;
}

::v-deep .btn-outline-primary:hover {
  background: #0043ac;
  color: white;
}

::v-deep .btn-outline-primary:not(:disabled):not(.disabled):active {
  background: #0043ac;
  color: white;
}

.b-transparent {
  background: transparent;
  border: none;

  :focus {
    background: transparent;
  }

  img {
    position: relative;
    bottom: 14px;
  }
}

.btn-secondary:hover {
  background: none;
}

.btn-secondary:focus {
  background: none;
}

.edit-option {
  background: #ffffff;
  border-radius: 4px;
  border: none;

  .separator {
    border: 0.5px solid #e8e8e8;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    width: 120px;
  }

  div {
    color: #52575c;
    font-size: 16px;
  }

  div:hover {
    color: #0043ac;
    cursor: pointer;
  }
}

.pagination-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.1px;
  color: #757575;
}

::v-deep .schedule-list__tab {
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: $nav-primary-color;
  padding: 0.5rem 1rem 1rem 1rem;

  &.active {
    color: #333333;
    border: none;
    border-bottom: 5px solid $nav-primary-color;

    &:hover {
      border-color: $nav-primary-color;
    }
  }

  &:hover {
    border-color: transparent;
  }
}
</style>
